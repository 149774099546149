import React,{useEffect, useState, useRef} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'reactstrap';
//css
import '../css/NewPost.css';

//icono
import { FiAlertTriangle } from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';
import { IoIosPaper } from 'react-icons/io';	

import Header from './Header';
import Navbar from './Navbar';

//ApiRest
import {getDatos, deleteDatos, ApiUrl, URL, patchDatos} from '../servicios/ApiRest';


const EditPost = () => {

    let navigate = useNavigate();
    let params   = useParams();

    const {idPublicacion} = params;

    const [idUsuario, setIdUsuario] = useState('');
    const [titulo, setTitulo]                = useState('');
    const [contenido, setContenido]          = useState('');
    const [documento, setDocumento]          = useState([]);
    const [categoria, setCategoria]          = useState([]);
    const [autor, setAutor]                  = useState('');

    const fileType                           =['application/pdf'];

    const firstUpdate                        = useRef(true);

    const [alert, isAlert]                   = useState( false );
    const [alertMessage, setAlertMessage]    = useState( "" );
    const [alertType, setAlertType]          = useState( "" );

    const [documentosObtenidos, setDocumentosObtenidos] = useState([]);
    const [post, setPost]                    = useState([]);

    const [loading, setLoading]              = useState( true );

    useEffect(() => { 
        let usuario = localStorage.getItem('user')
        let token = localStorage.getItem('token')
        
        if(usuario == null || usuario == undefined || usuario == "")
        {
            navigate('/');
        }
        else
       {
        let us = usuario;
            setAutor(us);  
            primerGet();    
       }
     }, []);

     const primerGet = () => {
        let usuario = localStorage.getItem('user')
        getDatos('q-blog-post-orderby-recientes', '/'+ idPublicacion )
        .then( responseJson => {
            //console.log("publicaciones son: " + JSON.stringify(responseJson));
                if (responseJson.length < 0)
                {
                    gestionarAlerta("Sin publicación", true, true);
                }
                else if (responseJson.status === 401)
                {
                        
                        localStorage.removeItem('token');
                        obtentoken();
                }
                else if (responseJson.id_user != usuario)
                {
                    navigate('/error');
                }
                else
                {

                    setPost(responseJson);
                    setTitulo(responseJson.titulo);
                    setContenido(responseJson.texto);
                    console.log(responseJson);
                    setCategoria(responseJson.nombres_categoria);
                    setDocumentosObtenidos(responseJson.ligas_documentos); 
                    setLoading(false);
                }

            })
            .catch((e) => {
                console.log('Aqui '+ e +' esta');
                gestionarAlerta("Hubo un error al visualizar los post. Intenta nuevamente más tarde.", true, true);
            });
    }

      const obtentoken = () => {
        getDatos('refresh-token', {
            "refresh_token": localStorage.getItem('refreshToken')
        }
        )
            .then(responseJson => {
              if(responseJson.token)
              {
              localStorage.setItem('token', responseJson.token);
              }
              else
              {
                gestionarAlerta("No se pudo cargar la pagina intenta nuevamente", "Error")
              }
            })
            .catch((e) => {
                console.log(e);
                
            });
      }
  
    function gestionarAlerta(mensaje, error, alerta)
    {
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);
    }

    const publicarPost = () =>{
        setLoading(true);
        //console.log("publicarPost: "+ titulo + contenido + "arr" + selected +"arr" + autor);
        console.log(documento);
        if(titulo.length <= 0 || contenido.length <= 0){
            gestionarAlerta("Todos los campos deben estar llenos.", true, true);
           
          }
        else
        {
          let date = new Date(),
          output= String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
          gestionarAlerta("", false, false);
          if(titulo.length  <= 0 || contenido.length <= 0)
          {
            gestionarAlerta("Todos los campos deben estar llenos.", true, true);
          }
          else {
              patchDatos('publicaciones', '/'+ idPublicacion, {

                    "titulo": titulo,
                    "texto": contenido,
                    "editado": output,
                }
                )
                .then(responseJson => {
                    //console.log("publicaciones editada: " + JSON.stringify(responseJson));
                    if(responseJson.status == 401)
                        {
                            localStorage.removeItem('token');
                            obtentoken();
                            gestionarAlerta("Error, Post no publicado", true, true);
                        }
                    else if (responseJson.status == 400 || responseJson.status == 404)
                    {
                        gestionarAlerta("Error, Post no se pudo actualizar", true, true);
                    }
                    else if(responseJson.status != "desconocido")
                    {
                        gestionarAlerta("Post actualizado exitosamente", false, true);

                        
                        if(documento.length > 0)
                        {
                                insertArchivos(idPublicacion);
                        }
                        else
                        {
                            primerGet();
                        }
                    }
                })
                .catch((e) => {
                  console.log("aqui" + e);
                  gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);   
                });
            }
        }
    }

    const subirArchivos = (docu) => {
      
        setDocumento(docu);
        gestionarAlerta("", false, false);
        
    }

    const insertArchivos = async(idPublicacion) =>{

        for(let i = 0; i < documento.length; i++)
        {
          let body = new FormData(), name = documento[i].name;
    
          body.append(
            'doc',
            documento[i]
          );
          body.append('id_publicacion', idPublicacion);
          body.append('url', URL + '/doc/' + name);

          console.log("archivos: ", body, "id publicacion: " + idPublicacion, documento[i]);
    
          await fetch(
            ApiUrl + 'documentos',
            {
              method: 'POST',
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
              body: body
            }
          )
            .then((res) => res.text())
            .then((res) => {
              if (res) {
                gestionarAlerta("Post actualizado exitosamente", false, true);
                primerGet();
              }
              else if (res[0].message) {
                gestionarAlerta(res[0].message, "Error");
                //setOpen(false)
              }
            })
            .catch((e) => {
              gestionarAlerta("Hubo un error. Intenta nuevamente más tarde.", "Error en el sistema" + e);
            })
        }
    }


    const redirect = () => {
        gestionarAlerta("Cancelando...", false, true); 
        setTimeout(function(){
            navigate('/Home')
            setLoading(true);
        },1000);  
    }

    return(
        <React.Fragment>

            {
                loading == true ?
                <div className="m-0 vh-100 row justify-content-center align-items-center">
                    <Spinner color="primary" />
                   
                </div>
                :
                <div>
                <div>
                        <Header />
                        <Navbar />
                </div>
                <div className="newPost">
                    <div className="newPost-header">
                        <h1 className="title">Editar Post</h1>
                    </div>
                    <section>
                        <article>
                            <br style={ { display: alert ? "" : "none" } }/>
                                <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                                    {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                                    { alertMessage }
                                </div>
                            <div className="newPost-body">
                                <div className="newPost-body-titulo col-md-12">
                                    <label>Titulo</label>
                                    <br/>
                                    <input className="form-control form-control" type="text"  value={titulo} onChange={(e) => setTitulo(e.target.value)}/>
                                </div>
                                <div className="newPost-body-contenido">
                                    <label>Contenido: </label>
                                    <br/>
                                    <textarea className="contenido" value={contenido} onChange={(e) => setContenido(e.target.value)}/>
                                </div>
                                <br/>
                                <div className="newPost-body-documento">
                                    <label>Documento: </label>
                                    <input type="file" name="files" multiple onChange={(e) => subirArchivos(e.target.files)}/>
                                </div>
                                <br/>
                                <br style={ { display: alert ? "" : "none" } }/>
                                <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                                    {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                                    { alertMessage }
                                </div>
                                <div className = "container categorias-section">
                                        <p className="p-1"> Categorias: </p>
                                        { categoria.map((categoria, id) => {
                                                return(

                                            <button className="btn btn-outline-secondary p-2 pt-0 pb-0 m-1" style = {{display: "inline-block"}}role="alert"
                                            key={id} >{categoria}</button>
                                                    
                                            );
                                        })}
                                    </div>
                                    {
                            
                                        documentosObtenidos[0] !== null ?
                                        documentosObtenidos.map((url, index) => {
                                            return(
                                                <div className="card bg-warning text-white" key={index}>
                                                <div className="card-body" key={index}>
                                                <IoIosPaper size="25px" color="#fff"/><a href={url} className="btn btn-ligth">Ver documento adicional</a>
                                                </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div/>

                                    }
                                <br/>
                                <div className="newPost-body-boton">
                                    <button className="m-5 mt-2 btn btn-success" 
                                    onClick={() => {publicarPost();}}>Actualizar</button>
                                    <button className="m-5 mt-2 btn btn-warning" onClick={()=>{redirect()}}>Cancelar</button>
                                    
                                </div>
                            </div>
                        </article>
                        <div className=" card-lateral card bg-light mt-2 col-md-3">
                            <div className="card-header text-center">
                                <p className="headertextcard">Redacta tu publicación</p>
                            </div> 
                            <div className="card-body text-center">
                                <p className="card-text">Ingresa un titulo referente al tema a publicar</p>
                                <p className="card-text">Ingresa un contenido para tu publicación, se especifico con lo que quieres poner</p>
                                <p className="card-text">Selecciona una categoria para tu publicación</p>
                                <p className="card-text">Puedes agregar  o no un documento que ayude a tu publicación</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            }
        </React.Fragment>
    );
}

export default EditPost;
