/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef} from 'react';
import { useNavigate} from 'react-router-dom';


//css
import '../css/Login.css';

//icono
import { FiAlertTriangle} from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';

//ApiRest
import {getDatos } from '../servicios/ApiRest';


const Login = () => {

    let navigate = useNavigate();
    //const usuario = "";

    const [correo, setCorreo]           = useState('');
    const [password, setPassword]       = useState('');

    const [alert, isAlert]                   = useState( false );
    const [alertMessage, setAlertMessage]    = useState( "" );
    const [alertType, setAlertType]          = useState( "" );
    const [visible, setVisible]              = useState(false)


    const firstUpdate                        = useRef(true);

    useEffect(() => {
        let user = localStorage.getItem('user');
        if(user){
            navigate('/home');
        }
    } , []);
    useEffect(() => {
        if(firstUpdate.current){
            firstUpdate.current = false;
            return;
        }
        if (correo.length <= 0 || password.length <= 0) 
            {
                gestionarAlerta("Usuario o Contraseña vacíos.", true, true);
            }
            else
            {
                gestionarAlerta("", false, false);
            }

    } , [correo, password]);

    function gestionarAlerta(mensaje, error, alerta)
    {
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);
    }

    const signIn = () => 
    {
        // redirect();
        if (correo.length <= 0 || password.length <= 0) 
            {
                gestionarAlerta("Usuario o Contraseña vacíos.", true, true);
            }
            else
            {
                getDatos('auth/logins', '?username=' + correo +'&password='+ password)
                .then( responseJson => {
                    console.log(responseJson);
                        if (responseJson.password) 
                        {
                            gestionarAlerta("Correo electrónico o contraseña incorrecto", true, true);
                        }
                        else
                        {
                            localStorage.setItem('token', responseJson.token);
                            localStorage.setItem('refreshToken', responseJson.refresh_token);
                            localStorage.setItem('user', responseJson.user.id);
                            localStorage.setItem('correo', responseJson.user.username);
                            redirect();
                            
                        }

                    })
                    .catch((e) => {
                        console.log('Aqui '+ e +' esta');
                        gestionarAlerta("Hubo un error. Intenta nuevamente más tarde.", true, true);
                    });
                }
    }

    const redirect = () => {
        let user = localStorage.getItem('token');
        console.log(localStorage.getItem('user'));
        console.log(localStorage.getItem('correo'));

        if (user != null) 
        {
            gestionarAlerta("Iniciando sesion...", false, true); 
            setTimeout(function(){
                navigate('/Home')
            },1000);  
        }
        else{
            gestionarAlerta("Sesion no iniciada", true, true);
          
        }

        // localStorage.setItem('user', 5);
        // localStorage.setItem('correo', usuario);
        // gestionarAlerta("Iniciando sesion...", false, true); 
        //     setTimeout(function(){
        //         navigate('/Home')
        //     },1000);
      
    }

    function enterT (e) {
        if (e.keyCode === 13) {
            signIn();
        }
    }

    window.onkeydown = enterT;
    

    return(
        <React.Fragment>
           <div className="wrapper fadeInDown">
             <div id="formContent" className='card'>
                 <div className="fadeIn first">
                 <br/>
                 <h1> Blog </h1>
                 <h3> Electrónica Automotriz</h3>
                 <br/>
                 <br/>
                 </div>
                 <form>
                     <input type="text" id="login" className="fadeIn second" name="login" placeholder="Correo"
                     onChange={(e) => setCorreo(e.target.value)}/>
                     <br/>
                     <input type={visible == false ? 'password' : 'text'} id="password" className="fadeIn third" name="password" placeholder="Contraseña"
                     onChange={(e) => setPassword(e.target.value)}/>
                     <br/>
                     <button type="button" className="btn btn-link" onClick={() => {setVisible( !visible)}} style={{marginLeft:150}}>Mostrar contraseña</button>
                     <br/>
                     <br/>
                     <input type="button" className = "fadeIn fourth"value="Iniciar Sesión" onClick={ () => { signIn() }}/>
                    
                    <br/>
                    <br style={ { display: alert ? "" : "none" } }/>
                    <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                        {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                        { alertMessage }
                    </div>
                    <br/>
                     <br/>
                 </form>
             </div>
          </div>
        </React.Fragment>
    );
}

export default Login;