import React, { useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp, faMessage} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//css
import '../css/Home.css';

//paginas
import Header from './Header';
import Navbar from './Navbar';

import {getDatos, postDatos } from '../servicios/ApiRest';

//icono
import { FiAlertTriangle } from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';
import { IoIosPaper } from 'react-icons/io';	


const ViewPost = () => {

    let navigate = useNavigate();
    let params   = useParams();

    const {idPublicacion} = params;

    const [ post, setPost ]                  = useState( [] );
    const [categorias, setCategorias]        = useState( [] );
    const [comentarios, setComentarios]      = useState( [] );
    const [documentos, setDocumentos]        = useState( [] );

    const [comenta, setComenta]              = useState( "" );
    const [idUser, setIdUser]                = useState( "" );

    const [alert, isAlert]                   = useState( false );
    const [alertMessage, setAlertMessage]    = useState( "" );
    const [alertType, setAlertType]          = useState( "" );

    const [loading, setLoading]              = useState( true );


    useEffect(() => {
        let usuario = localStorage.getItem('user');
        if(usuario == null || usuario === undefined || usuario === "")
        {
            navigate('/');
        }
        else
       {
        primerGet(usuario);
        setIdUser(usuario);
       }
    }, []); 

    const primerGet = () => {
        getDatos('q-blog-post-orderby-recientes', '/'+ idPublicacion )
        .then( responseJson => {
            //console.log("publicaciones son: " + JSON.stringify(responseJson));
                if (responseJson.length < 0)
                {
                    gestionarAlerta("Sin publicación", true, true);
                }
                else if (responseJson.status === 401)
                {
                        
                        localStorage.removeItem('token');
                        //localStorage.removeItem('refreshToken');
                        obtentoken();
                }
                else
                {
                    //gestionarAlerta("se encontro la publicacion", false, true);
                    setPost(responseJson);
                    console.log(responseJson);
                    setCategorias(responseJson.nombres_categoria);
                    setDocumentos(responseJson.ligas_documentos);
                    //console.log(responseJson.ligas_documentos);
                    obtenComentarios(idPublicacion);
                    setLoading(false);
                }

            })
            .catch((e) => {
                console.log('Aqui '+ e +' esta');
                gestionarAlerta("Hubo un error al visualizar los post. Intenta nuevamente más tarde.", true, true);
            });
    }

    const obtentoken = () => {
        getDatos('refresh-token', {
            "refresh_token": localStorage.getItem('refresh_token')
           }
        )
            .then(responseJson => {
              localStorage.setItem('token', responseJson.token);
              //localStorage.setItem('refreshToken', responseJson.refresh_token);
              primerGet();
            })
            .catch((e) => {
                console.log(e);
                
            });
      }


    const obtenComentarios = (idPublicacion) => {
        getDatos('q-blog-post-comentarios', '?id_publicacion='+ idPublicacion )
        .then( responseJson => {
            console.log("comentarios son: " + JSON.stringify(responseJson));
                if (responseJson.length < 0)
                {
                    gestionarAlerta("No hay comentarios por el momento", true, true);
                }
                else
                {

                    setComentarios(responseJson);
                    
                }

            })
            .catch((e) => {
                console.log('Aqui '+ e +' esta');
                gestionarAlerta("Hubo un error al visualizar los post. Intenta nuevamente más tarde.", true, true);
            });
    }

    const sendComentario = (referencia) => {
        console.log("data:"+ idUser, idPublicacion, comenta);
        let ref = "@"+ referencia + ': '+ comenta;
        if(comenta.length < 10)
        {
            gestionarAlerta("El comentario debe tener al menos 10 caracteres", true, true);
        }
        else
        {
            
            postDatos('comentarios', {

                "id_user": parseInt(idUser, 10),
                "id_publicacion": idPublicacion,
                "comentario": referencia == null ? comenta: ref,
                "votos": 0,
            }
            )
            .then(responseJson => {
                //console.log("el comentario es: " + JSON.stringify(responseJson));
                if(responseJson.id)
                {
                    gestionarAlerta("Comentario enviado", false, true);
                    obtenComentarios(idPublicacion);
                    setComenta("");
                    
                }
                else
                {
                  gestionarAlerta("Error comentario no publicado", true, true);
                  obtenComentarios(idPublicacion);

                }
            })
            .catch((e) => {
              console.log("aqui" + e + "esta");
              gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);   
            });
        }

    }

    const votoComentario = (idComentario, valor, hayVoto) => {
                postDatos('voto-comentarios', {
                    "id_comentario": idComentario,
                    "valor": valor,
                }
                )
                .then(responseJson => {
                    console.log(responseJson);
                    obtenComentarios(idPublicacion);
                })
                .catch((e) => {
                    console.log("aqui" + e + "esta");
                    gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);
                });
    }

    const votoPublicacion = (valor, hayVoto) => {
       
                postDatos('voto-publicaciones', {
                    "id_publicacion": idPublicacion,
                    "valor": valor,
                }
                )
                .then(responseJson => {
                    console.log(responseJson);
                    primerGet();
                })
                .catch((e) => {
                    console.log("aqui" + e + "esta");
                    gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);
                });
        
    }

    function gestionarAlerta(mensaje, error, alerta)
    {
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);
    }

    function enterT (e) {
        if (e.keyCode === 13) {
            sendComentario();
        }
    }

    window.onkeydown = enterT;

    return (
        
            <div className="container">
                {
                loading == true ?
                <div className="m-0 vh-100 row justify-content-center align-items-center">
                    <Spinner color="primary" />
                   
                </div>
                :
                <div>
                <div>
                     {/* Navbar */}
                     <Navbar />
                    <br/>
                    <br/>
                    <br/>
                    
                    {/* header */}
                        <Header />
                    {/* cuerpo */}
                </div>
                <br/>
                <br/>
                <div className="card">
                    <div className="card-body">
                    
                    <br/>
                        <h3 className="card-title">{post.titulo}</h3>
                        <br/>
                        <p className="card-text text-justify">{post.texto}</p>
                        <br/>
                            Vota por este post:
                        <br/><br/>
                        <div className="row">
                            <div className="col-12 col-md-1 m-0">
                                <button type="button" className="btn btn-primary w-100"
                                onClick={()=>{votoPublicacion(1, true)}}><FontAwesomeIcon icon={faThumbsUp} /></button>
                            </div>
                            <div className="col-12 col-md-auto">
                                <button type="button" className="btn btn-outline-dark w-100" disabled={true}>{post.votos}</button>
                            </div>
                            <div className="col-12 col-md-1">
                                <button type="button" className="btn btn-primary w-100"
                                onClick={()=>{votoPublicacion(-1, true)}}><FontAwesomeIcon icon={faThumbsDown} /></button>
                            </div>
                        </div>
                    </div>
                    {
                        
                        post.url_imagen != null ?
                        post.url_imagen.map((url, index) => {
                            return(
                                <div className="card bg-warning text-white" key={index}>
                                <div className="card-body" key={index}>
                                    <img className="card-img-top" src={url} alt="Card image cap" />
                                </div>
                                </div>
                            )
                        })
                        :
                        <div/>

                    }
                    {
                        
                        documentos[0] !== null ?
                        documentos.map((url, index) => {
                            return(
                                <div className="card bg-warning text-white" key={index}>
                                <div className="card-body" key={index}>
                                <IoIosPaper size="25px" color="#fff"/><a href={url} className="btn btn-ligth">Ver documento adicional</a>
                                </div>
                                </div>
                            )
                        })
                        :
                        <div/>

                    }
                    
                    <p className="card-text p-4"><small className="text-muted">Publicado el: {post._creado}</small></p>
                    <div className = "container categorias-section">

                        <p className="p-1"> Categorias: </p>
                        { categorias.map((categoria, id) => {
                                return(

                            <button className="btn btn-outline-secondary p-2 pt-0 pb-0 m-1" style = {{display: "inline-block"}}role="alert"
                            key={id} >{categoria}</button>
                                    
                            );
                        })}
                    </div>
                    <br style={ { display: alert ? "" : "none" } }/>
                    <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                        {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                        { alertMessage }
                    </div>

                    {/*  seccion comentarios */}
                    <div className='container comments-section'>
                        <h4 className="p-2"> Comentarios </h4>
                        <textarea className="form-control" rows="3" maxLength="300" style={ { resize: "none" } }
                        value={comenta} onChange={(e) => setComenta(e.target.value)}/>
                        <div className="row mt-2">
                            <div className="col-12 col-md-2">
                                <button type="button" className="btn btn-primary w-100"
                                onClick={()=>{sendComentario()}}><FontAwesomeIcon icon={faMessage} />  &nbsp;Comentar</button>
                            </div>
                        </div>
                        <hr/>
                        <div className="comments">
                            <div className="card bg-light mb-3">
                                {
                                    comentarios.length <= 0 ?
                                    <div className="card-body">
                                        <p className="card-text">No hay comentarios por el momento</p>
                                    </div>
                                    :
                                comentarios.map((comentario, id) => {
                                    return(
                                <div className="card-body" key={id}>
                                    <h5 className="card-title"><strong>{comentario.nombre_comenta}</strong></h5>
                                    <p className="card-text">{comentario.comentario}</p>
                                    <p className="card-text p-1"><small className="text-muted">Comentario realizado el {comentario.comentario_fecha}</small></p>
                                    <div className="row">
                                        <div className="col-12 col-md-1 m-0">
                                            {/* boton que suma comentario */}
                                            <button type="button" className="btn btn-primary w-100"
                                            onClick={()=>{votoComentario(comentario.id_comentario, 1, true)}}><FontAwesomeIcon icon={faThumbsUp} /></button>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <button type="button" className="btn btn-outline-dark w-100" disabled={true}>&nbsp; {comentario.comentario_votos} &nbsp;</button>
                                        </div>
                                        <div className="col-12 col-md-1">
                                            {/* boton que resta comentario */}
                                            <button type="button" className="btn btn-primary w-100"
                                            onClick={()=>{votoComentario(comentario.id_comentario, -1, true)}}><FontAwesomeIcon icon={faThumbsDown} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-3">
                                            <a>Responder: </a>
                                        </div>
                                    </div>
                                        <div className="card-body" key={id}>
                                                <input className="form-control" rows="2" maxLength="100" style={ { resize: "none" } }
                                                 onChange={(e) => setComenta(e.target.value)}/>
                                                <div className="row mt-2" >
                                                    <div className="col-12 col-md-2">
                                                        <button type="button" className="btn btn-primary w-100"
                                                         onClick={()=>{sendComentario(comentario.nombre_comenta)}}
                                                         disabled={comenta.length >= 10 ? false: true}> &nbsp;Enviar</button>
                                                    </div>
                                                </div>
                                        </div>
                                 </div>
                                 );
                                })
                                }
                            </div>
                        
                        </div>
                    </div>
                 
                </div>
                </div>
                }
            
            </div>
    );

}
export default ViewPost;