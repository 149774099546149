import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//ApiRest
import {getDatos } from '../servicios/ApiRest';

//paginas
import Header from './Header';
import Navbar from './Navbar';
import PostItem from './PostItem';
//import '../css/Home.css';

//icono
import { FiAlertTriangle } from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';
import { BsSearch } from "react-icons/bs";



const Home = () => {

    let navigate = useNavigate();

    const [pagina, setPagina]                = useState('');
    
    const [ post, setPost ]                  = useState( [] );
    const [ post2, setPost2 ]                = useState( [] );
    const [buscador, setBuscador]            = useState('');

    const [alert, isAlert]                   = useState( false );
    const [alertMessage, setAlertMessage]    = useState( "" );
    const [alertType, setAlertType]          = useState( "" );

    const [loading, setLoading]              = useState( false );

    useEffect(() => {
        let usuario = localStorage.getItem('user')
        if(usuario == null || usuario == undefined)
        {
            navigate('/');
        }
        else 
        {
            getDatos('q-blog-post-orderby-recientes', '')
                .then( responseJson => {
                 console.log("publicaciones son: " + responseJson);
                        if (responseJson.length < 0)
                        {
                            gestionarAlerta("Sin publicaciones", true, true);
                        }
                        else if (responseJson.status === 401)
                        {
                            
                            localStorage.removeItem('token');
                            obtentoken();
                        }
                        else
                        {
                            
                            //gestionarAlerta("Publicaciones", false, true);
                            setPost(responseJson);
                            setPost2(responseJson);
                            //setItemPagina(responseJson.splice( 0, 10 ));
                            setPagina(1);
                            setLoading(false);
                        }

                    })
                    .catch((e) => {
                        console.log('Aqui '+ e +' esta');
                        gestionarAlerta("Hubo un error al visualizar los post. Intenta nuevamente más tarde.", true, true);
                    });
        }

    },[]);


    const obtentoken = () => {
        getDatos('refresh-token', {
            "refresh_token": localStorage.getItem('refresh_token')
        }
        )
            .then(responseJson => {
                if(responseJson.token)
                {
                    console.log("token es: " + responseJson);
                    localStorage.setItem('token', responseJson.token);
                    primergetDatos(1);

                }
                else
                {
                    gestionarAlerta("No se pudo cargar la pagina intenta nuevamente", "Error");
                }

            })
            .catch((e) => {
                console.log(e);
                
            });
      }

    const primergetDatos = (page) => {
        getDatos('q-blog-post-orderby-recientes', '?page=' + page)
                .then( responseJson => {
                    //console.log("publicaciones son: " + responseJson);
                        if(responseJson.status == 401)
                        {
                            localStorage.removeItem('token');
                            obtentoken();
                        }
                        else if (responseJson.length < 0)
                        {
                            gestionarAlerta("Sin publicaciones", true, true);
                        }
                        else
                        {
                            setPost(responseJson);
                            setPost2(responseJson);
                            setLoading(false);
                        }

                    })
                    .catch((e) => {
                        console.log('Aqui '+ e +' esta');
                        gestionarAlerta("Hubo un error al visualizar los post. Intenta nuevamente más tarde.", true, true);
                    });
    }
    

    function gestionarAlerta(mensaje, error, alerta)
    {
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);
    }

    const handleChange = (e) => {
        setBuscador(e.target.value);
        filtrar(e.target.value);
    }

    const filtrar = ( terminoBusqueda ) =>
    {
        var resultadoBusqueda = post2.filter( post => {
            if(post.titulo.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()))
            {
                return post;
            }
         } );  
            setPost(resultadoBusqueda);
    }

    const prevHandle = () => {

        const previewpage = pagina-1;

        if(previewpage < 1) return;
        const firstPage = previewpage * 10;
        primergetDatos(previewpage);
        setPagina( previewpage );
    }

    const nextHandle = () => {

        const nextPage = pagina + 1;
        primergetDatos(nextPage);
        setPagina( nextPage );
    }


    return (

        <div>
            {
                loading == true ?
                <div className="m-0 vh-100 row justify-content-center align-items-center">
                    <Spinner color="primary" />
                   
                </div>
                :
                <div>
                <div>
                    {/* Navbar */}
                        <Navbar />
                    <br/>
                    <br/>
                    <br/>
                    
                    {/* header */}
                        <Header />
                    {/* cuerpo */}                    
                    
                    </div>
                    <br/>
                    <div className="container-md">
                        <div className="row">
                            <div className="col-10 col-md-5">
                                <br/>
                                <h4>Publicaciones</h4>
                            </div>
                            <div className="col-md-7 row" style={{alignSelf: "flex-end",}}>
                                <div className="search">
                                        <input type="text" className="input-search-active " value={buscador} placeholder="Buscar" onChange={handleChange}
                                        style={{height:"40px", width:"67%"}}/>
                                        <BsSearch size="20px" color='black'className="searchIcon"/>
                                </div>
                                <div className="btn-group float-end col-md-9" role="group" aria-label="Basic example">
                                    <button type="button" 
                                            className = "btn btn-light"
                                            style={ { width: "40px", height:"35px"} }
                                            disabled={pagina == 1 ? true : false}
                                            onClick = { () => prevHandle()  } 
                                    >
                                        anterior
                                    </button>
                                    <div className='m-2 btn btn'>
                                        <a>{pagina}</a>
                                    </div>
                                    <button type="button" 
                                            className = "btn btn-light" 
                                            style={ { width: "40px", height:"35px"} }
                                            onClick = { () => nextHandle() }
                                            disabled = { post.length < 20 ? true : false }
                                    >
                                        siguiente
                                    </button>
                                </div>
                            </div>

                        </div>
                        <br style={ { display: alert ? "" : "none" } }/>
                            <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                                {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                                { alertMessage }
                            </div>
                            <br/>
                        <hr/>
                        <br/>
                        {
                            post.length >=1 ?
                            
                                post.map((blog, id) => {
                                    return <PostItem key={id} blog={blog} />
                                })
                            
                            :

                            <div>
                                <h5>No hay publicaciones por el momento</h5>
                            </div>
                        }
                        
                        <br/>
                        <br/>
                </div>
                </div>
            }
            
        </div>
    );

}
export default Home;
