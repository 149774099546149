import React,{useEffect, useState, useRef} from "react";
import {useNavigate} from 'react-router-dom';

//css
import '../css/NewPost.css';

//icono
import { FiAlertTriangle } from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';

import Header from './Header';
import Navbar from './Navbar';

//ApiRest
import {getDatos, postDatos, ApiUrl, URL} from '../servicios/ApiRest';


const NewPost = () => {

    let navigate = useNavigate();

    const [titulo, setTitulo]                = useState('');
    const [contenido, setContenido]          = useState('');
    const [documento, setDocumento]          = useState([]);
    const [categoria, setCategoria]          = useState([]);
    const [autor, setAutor]                  = useState('');

    const fileType                           =['application/pdf'];

    const [selected, setSelected]            = useState([]);

    const firstUpdate                        = useRef(true);

    const [alert, isAlert]                   = useState( false );
    const [alertMessage, setAlertMessage]    = useState( "" );
    const [alertType, setAlertType]          = useState( "" );

    useEffect(() => { 
        let usuario = localStorage.getItem('user')
        let token = localStorage.getItem('token')
        
        if(usuario == null || usuario === undefined || usuario === "")
        {
            navigate('/');
        }
        else
       {
        let us = parseInt(usuario, 10);   
            setAutor(us);
            obtenCategorias();
      }
     }, []);

      const obtenCategorias = () =>{
        getDatos('categorias', '')
            .then(responseJson => {
              if(responseJson.status === 401)
              {
                localStorage.removeItem('token')
                obtentoken();
              }
                console.log("categorias son: " +responseJson);
                setCategoria(responseJson);
                
            })
            .catch((e) => {
                console.log(e);
                
            });
        }

      const obtentoken = () => {
        getDatos('refresh-token', {
            "refresh_token": localStorage.getItem('refreshToken')
        }
        )
            .then(responseJson => {
              if(responseJson.token)
              {
              localStorage.setItem('token', responseJson.token);
              obtenCategorias();
              }
              else
              {
                gestionarAlerta("No se pudo cargar la pagina intenta nuevamente", "Error")
              }
            })
            .catch((e) => {
                console.log(e);
                
            });
      }
  
    function gestionarAlerta(mensaje, error, alerta)
    {
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);
    }

    const publicarPost = () =>{
        //console.log("publicarPost: "+ titulo + contenido + "arr" + selected +"arr" + autor);
        console.log(documento);
        if(titulo.length <= 0 || contenido.length <= 0 || selected.length <= 0 ){
            gestionarAlerta("Todos los campos deben estar llenos.", true, true);
           
          }
        else
        {
          let date = new Date(),
          output= String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
          gestionarAlerta("", false, false);
          if(titulo.length  >0 && contenido.length > 0 && selected.length > 0 )
          {
            console.log("es el select", selected);
              postDatos('publicaciones', {

                    "titulo": titulo,
                    "texto": contenido,
                    "id_user": autor,
                    "editado": output,
                }
                )
                .then(responseJson => {
                    console.log("publicaciones son: " + JSON.stringify(responseJson));
                    if(responseJson.status == 401)
                        {
                            localStorage.removeItem('token');
                            obtentoken();
                            gestionarAlerta("Error Post no publicado", true, true);
                        }
                    else if(responseJson.id)
                    {
                      console.log(categoria);
                      selected.map( (item) => {
                          insertCategoria(responseJson.id, item.id);
                        });
                        if(documento.length > 0)
                        {
                                insertArchivos(responseJson.id);
                        }
                        setTitulo('');
                        setContenido('');
                        setSelected([]);
                    }
                    else
                    {
                      gestionarAlerta("Error Post no publicado", true, true);
                    }
                })
                .catch((e) => {
                  console.log("aqui" + e);
                  gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);   
                });
            }
        }
    }

    const insertCategoria = (id_publicacion, item) =>{
        console.log("id pub: "+ id_publicacion);

        postDatos('categoria-publicaciones', {

            "id_categoria": item,
            "id_publicacion": id_publicacion,
        }
        )
        .then(responseJson => {

          if(responseJson.id)
            {
            gestionarAlerta("Post publicado con exito", false, true);
               
            }
            else
            {
              gestionarAlerta("Error Post no publicado", true, true);
            }
        })
        .catch((e) => {
          console.log("aqui" + e);
          gestionarAlerta("Error en el sistema intente nuevamente mas tarde", true, true);   
        });
    }

    const subirArchivos = (docu) => {
      
        setDocumento(docu);
        gestionarAlerta("", false, false);
        
      
    }

    const insertArchivos = async(idPublicacion) =>{

        for(let i = 0; i < documento.length; i++)
        {
          let body = new FormData(), name = documento[i].name;
    
          body.append(
            'doc',
            documento[i]
          );
          body.append('id_publicacion', idPublicacion);
          body.append('url', URL + '/doc/' + name);

          console.log("archivos: ", body, "id publicacion: " + idPublicacion, documento[i]);
    
          await fetch(
            ApiUrl + 'documentos',
            {
              method: 'POST',
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
              body: body
            }
          )
            .then((res) => res.text())
            .then((res) => {
              if (res) {
                gestionarAlerta("Todo correcto");
                gestionarAlerta("Post publicado con exito", false, true);
              }
              else if (res[0].message) {
                gestionarAlerta(res[0].message, "Error");
                //setOpen(false)
              }
            })
            .catch((e) => {
              gestionarAlerta("Hubo un error. Intenta nuevamente más tarde.", "Error en el sistema" + e);
            })
        }
    }

    const handleChange = (e, data) =>{
        const {id, checked} = e.target;
        if (checked) {
            if (id === "allSelect") {
                setSelected(categoria);
            } else {
              setSelected([...selected, data]);
            }
          } else {
            if (id === "allSelect") {
                setSelected([]);
            } else {
              let tempuser = selected.filter((item) => item.id !== data.id);
              setSelected(tempuser);
             
            }
        }
        
        console.log("selected: ", selected);
        console.log("doc: ", documento);
    }

    const redirect = () => {
      gestionarAlerta("Cancelando...", false, true); 
      setTimeout(function(){
          navigate('/Home')
      },1000);  
  }

    return(
        <React.Fragment>
             <div>
                    <Navbar />
                    <br/>
                    <br/>
                    <br/>
                    <Header />

            </div>
            <div className="newPost">
                <div className="newPost-header">
                    <h1 className="title">Nuevo Post</h1>
                </div>
                <section>
                      <article>
                          <br style={ { display: alert ? "" : "none" } }/>
                              <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                                  {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                                  { alertMessage }
                              </div>
                          <div className="newPost-body">
                              <div className="newPost-body-titulo col-md-12">
                                  <label>Titulo</label>
                                  <br/>
                                  <input className="form-control form-control" type="text"  value={titulo} onChange={(e) => setTitulo(e.target.value)}/>
                              </div>
                              <div className="newPost-body-contenido">
                                  <label>Contenido: </label>
                                  <br/>
                                  <textarea className="contenido" value={contenido} onChange={(e) => setContenido(e.target.value)}/>
                              </div>
                              <br/>
                              <div className="newPost-body-documento">
                                  <label>Documento: </label>
                                  <input type="file" name="files" multiple onChange={(e) => subirArchivos(e.target.files)}/>
                              </div>
                              <br/>
                              <br style={ { display: alert ? "" : "none" } }/>
                              <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                                  {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                                  { alertMessage }
                              </div>
                              <label>Selecciona una categoria: </label>
                              <div className="newPost-body-categoria col-md-12 row">
                                      {categoria.map((data, index) => (
                                          <div key={index}>
                                                <input type="checkbox" 
                                                className="form-check-input"
                                                id={data.id}
                                                checked={selected.filter((item) => item.id === data.id).length > 0}
                                                onChange={(e) => handleChange(e, data)}/>
                                                <label>{data.nombre}</label>   
                                          </div>    
                                                )
                                      )}
                                  
                              </div>
                              <br/>
                              <div className="newPost-body-boton">
                                  <button className="m-5 mt-2 btn btn-success" 
                                  onClick={() => {publicarPost();}}>Publicar</button>
                                  <button className="m-5 mt-2 btn btn-danger" onClick={()=>{redirect()}}>Cancelar</button>
                              </div>
                          </div>
                      </article>
                      <div className=" card-lateral card bg-light mt-2 col-md-3">
                          <div className="card-header text-center">
                              <p className="headertextcard">Redacta tu publicación</p>
                          </div> 
                          <div className="card-body text-center">
                            <p className="card-text">Ingresa un titulo referente al tema a publicar</p>
                            <p className="card-text">Ingresa un contenido para tu publicación, se especifico con lo que quieres poner</p>
                            <p className="card-text">Selecciona una categoria para tu publicación</p>
                            <p className="card-text">Puedes agregar  o no un documento que ayude a tu publicación</p>
                          </div>
                      </div>
                </section>
            </div>
        </React.Fragment>
    );
}

export default NewPost;
