import React from "react";
import '../css/Header.css';

const Header = () => {
    return (
    <header className="home-header">
        <h1>
            <span>"<span>Blog Electronica automotriz Querétaro</span>"</span>
        </h1>
        <p>
            informacion relevante sobre documentos (manuales), preguntas y respuestas
            <br/>
            acerca de reparaciones y mantenimientos de vehiculos
        </p>
    </header>
    );
}
export default Header;