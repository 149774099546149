import React from "react";


const Error = () => {

    return[
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <img src="https://img.freepik.com/vector-gratis/icono-error-minimo-moderno-no-encontrado-vaya-pagina-no-encontrada-404-error-pagina-no-encontrada-concept_599740-716.jpg?w=2000" 
                          className="m-0 vh-100 row justify-content-center align-items-center"alt=""/>
                </div>
            </div>
        </div>
    ]
}

export default Error;