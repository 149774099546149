import React, { useState} from 'react';
import { NavLink } from 'react-bootstrap';

// import Imaaplication from './Imaaplication';

//icono
import { FiAlertTriangle } from "react-icons/fi";
import { GiCheckMark } from 'react-icons/gi';


const Curso = () => {

const [nombre, setNombre]                 = useState('');
const [apellidos, setApellidos]           = useState('');
const [telefono, setTelefono]             = useState('');
const [correo, setCorreo]                 = useState('');
const [nTaller, setNTaller]               = useState('');
const [telTaller, setTelTaller]           = useState('');
const [direccion, setDireccion]           = useState('');

const [alert, isAlert]                   = useState( false );
const [alertMessage, setAlertMessage]    = useState( "" );
const [alertType, setAlertType]          = useState( "" );

function gestionarAlerta(mensaje, error, alerta)
{
        isAlert(alerta);
        setAlertType(error ? "danger" : "success");
        setAlertMessage(mensaje);   
}

  
  return (
    <React.Fragment >
        <div className='container col col-sm-2'>
          <div className='col-col-md-20' style={{marginTop:100, marginLeft:80}}>
            <div className='row col col-md-15'>
            <h5 style={{marginLeft:50}}><b>Curso Teórico Practico de Sensores Automotrices Diagnóstico, Medición y Simulación</b></h5>
            <br/>
            <br/>
            <br/>
              <div className='col col-md-5'>
                <br/>
                <p>Los participantes tendrán mediante 12 sesiones teóricas prácticas, los conocimientos requeridos para poder diagnosticar, medir e identificar en los diagramas todos sensores y así poder determinar su funcionalidad.
                Incluye en el curso el simulador de los avances de tiempo por medio de un control de modulación de ancho de pulso.
                </p>
              </div>
             
              <div className='col col-md-6 card bg-dark text-white' style={{marginLeft:50, marginTop:20}}>
                <center><h6><b>Proximamente</b></h6></center>
                <br/>
                
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <div className='row col col-sm-20'>
                <h5><b>Registrate a nuestro curso</b></h5>
              
                    <div className='col col-md-10'>
                      <p>Nombre</p>
                      <input type="text" id="nombre" className='form-control'
                      onChange={(e) => setNombre(e.target.value)}/>
                    </div>
                    <br/>
                    <div className='col col-md-10'>
                        <p>Apellidos</p>
                        <input type="text" id="apellidos" className='form-control' 
                        onChange={(e) => setApellidos(e.target.value)}/> 
                    </div>
                    <div className='col col-md-10' >
                      <p>Correo</p>
                      <input type="text" id="correo" className='form-control'
                      onChange={(e) => setCorreo(e.target.value)}/> 
                    </div>
             
                    <div className='col col-md-10' >
                      <p>Telefono</p>
                      <input type="text" id="telefono"  className='form-control'
                      onChange={(e) => setTelefono(e.target.value)}/>
                    </div>
             
                    <div className='col col-md-10' >
                      <p>Ingresa el nombre del taller al que perteneces</p>
                      <input type="text" id="nTaller" className='form-control' 
                      onChange={(e) => setNTaller(e.target.value)}/>
                    </div>
                    <div className='col col-md-10' >
                      <p>Ingresa el telefono del taller </p>
                      <input type="text" id="nombre" className='form-control'
                      onChange={(e) => setTelTaller(e.target.value)}/>
                    </div>
               
                    <div className='col col-md-10' >
                  <p>Ingresa la direccion del taller</p>
                  <input type="text" id="nombre"  className='form-control'
                  onChange={(e) => setDireccion(e.target.value)}/>
                  </div>
                  
                  <br/>
                  <br/>
                  <div className='col col-md-10' >
                     <input type="button" className = "fadeIn fourth"value="Registrar"/>   
                  </div>
                    <br style={ { display: alert ? "" : "none" } }/>
                    <div className= { "alert alert-" + alertType } role="alert" style={ { display: alert ? "" : "none" } }>
                        {alertType == "danger" ? <FiAlertTriangle size="20px" color="#FF3D3D"/> : <GiCheckMark size="20px" color="#178030"/>}&nbsp;&nbsp;
                        { alertMessage }
                    </div>
                    <br/>
                     <br/>
                
              </div>


            <div className='card bg-dark text-white'>
              <p>frgtrebjbjnbgrnjibgrji grji vjirb</p>
            </div>
          </div>
            
        </div>
    </React.Fragment>
  )

}

export default Curso;