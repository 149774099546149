import React, { useState } from 'react';
import { useNavigate,} from 'react-router-dom';
import "../css/Modal.css";


function Modal({ setOpenModal }) {

  let navigate = useNavigate();

    const closeSesion = () => {

      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      setOpenModal(false);
      navigate('/');
    }
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h5>Estas seguro de cerrar sesión?</h5>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
          onClick={() => {
            closeSesion();
            }}
            >Aceptar</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;