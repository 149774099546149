import React, { useEffect, useState } from 'react';
import { useNavigate,} from 'react-router-dom';
import '../css/Navbar.css';

import Modal from './Modal';


//iconos
import { BsFillPersonFill } from "react-icons/bs";


const Navbar = (props) => {

    let navigate = useNavigate();
    const [usuario, setUsuario] = useState("");

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(()=> {
        setUsuario(localStorage.getItem('correo'));
    },[])

    const goScreen = (screen) => {

        switch(screen) {
            case 'Publicar':
                  navigate('/newPost');
                    break;
            case 'Cuenta':
                navigate('/cuenta');
                    break;
            case 'Home':
                navigate('/Home');
                    break;
            default:
                navigate('/Error');
        }
    }

    const closeSesion = () => {
        setModalOpen(true);
        // localStorage.removeItem('token');
        // localStorage.removeItem('refreshToken');
        // localStorage.removeItem('user');
        // navigate('/');
    }

  return(
    
<div className="navbar navbar-expand-sm bg-light navbar-light fixed-top">
        <ul className="navbar-nav">
            <li className='nav-item active'>
            <a><BsFillPersonFill size="40px" color='#593CFF'/> {usuario}</a>
            </li>
            <li className='nav-item'>
            <button className="btn"
            onClick={() => {
                goScreen("Home");
                }}> Home </button></li>
            <li className='nav-item'>
                <button
                    className="openModalBtn btn"
                    onClick={() => {
                    goScreen("Publicar");
                    }}>Publicar
                </button>
            </li>
            <li className="nav-item dropdown">
            <button className="btn"
            onClick={() => {
                // setModalOpen(true);
                closeSesion();
              }}> Cerrar Sesion </button>
            </li>
            
        </ul>
       

        {modalOpen && <Modal setOpenModal={setModalOpen} />}

    </div>

    
   )

 }

export default Navbar