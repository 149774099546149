import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { HashRouter  as Navega, Routes, Route } from 'react-router-dom';

//css
import './App.css';

//componenets
import Login from "./components/Login";
import Home from "./components/Home";
import NewPost from "./components/NewPost";
//import Cuenta from "./components/Cuenta";
import ViewPost from "./components/ViewPost";
import EditPost from "./components/EditPost";
import Curso from "./components/Curso";

import Error from "./components/Error";




function App() {
    return(
      <Navega>
          <div className="container">
              <Routes>
                  <Route exact path="/" element={<Login />} />
                  <Route exact path="/Home" element={<Home />} />
                  <Route exact path="/NewPost" element={<NewPost/>} />
                  <Route exact path="/ViewPost/:idPublicacion" element={<ViewPost />} />
                  <Route exact path="/EditPost/:idPublicacion" element={<EditPost />} />
                  <Route exact path="/curso" element={<Curso />} />
                  <Route path="*" element={<Error />} />
              </Routes>
          </div>
      </Navega>
    );
  }
export default App;
