export const URL = 'https://blog.electronicaautomotrizqueretaro.com';
export const ApiUrl = URL + '/v2/';


export const getDatos = ( modulo, filtro ) =>
{
    return fetch( ApiUrl + modulo + filtro, {

    
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => response.json())
}

export const postDatos = async ( modulo, json ) =>
{
    //console.log("postDatos: " + JSON.stringify(json));
    return await fetch( ApiUrl + modulo, {

        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify( json ),
    })
    .then(response => response.json())
}

export const patchDatos = async ( modulo, filtro, json ) =>
{
    return await fetch( ApiUrl + modulo + filtro, {
      
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')

        },
        body: JSON.stringify( json ),
    
    }).then(response => response.json())
}

export const deleteDatos = async ( modulo, filtro ) =>
{
    return await fetch( ApiUrl + modulo + filtro, {

 
    method: 'DELETE',
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }

    })
    .then(response => response)
}


