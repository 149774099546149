import React from "react";
import {Link} from 'react-router-dom';

import {postDatos } from '../servicios/ApiRest';


const postItem =({

    blog :{ 
        texto,
        _creado,
        votos,
        no_visitas,
        titulo,
        id,
        username,
        name,
        nombres_categoria,
        id_user
    },
   }) => {

    const viewurl = "/ViewPost/"+ id;
    const editUrl = "/EditPost/"+ id;
    const id_user_actual = localStorage.getItem('user');

    const ingresaVista = () => {
        let token = localStorage.getItem('token');
        postDatos('visita-publicaciones', {
            "id_publicacion": id,
            "valor": 1,
        },
        token
        )
        .then(responseJson => {
            
        })
        .catch((e) => {
            console.log(e);
        });
    }

    return(
        <div>
        <div className="row" style= {{ backgroundColor: "#F8F8FA"}} id={id}>

        <div className="col-12 col-md-2">
            <div className="row" >
                <p className="text-end m-1 mr-2"><small className="text-muted">votos: {votos}</small></p>
            </div>
            <div className="row" >
                <p className="text-end m-1 mr-2"><small className="text-muted">visualizaciones: {no_visitas}</small></p>
            </div>
        </div>

        <div className="col-12 col-md-8">
            <div className="row m-1">
                <Link to={viewurl} onClick={()=>{ingresaVista()}}><h5 className="link-primary">{titulo}</h5></Link>
            </div>
            <div className="m-2">
            <p className="text-start m-1 mr-2"><small className="text-muted">Categoria</small></p>
                {nombres_categoria.map((categoria, id) => {
                    return(
                <button className="btn btn-outline-secondary p-2 pt-0 pb-0 m-1" style = {{display: "inline-block"}}role="alert"
                key={id}>
                    {categoria}
                </button>
                    );
                })}
            </div>
        </div>

        <div className="col-12 col-md-2">
            <div className="row" >
                <p className="text-start m-1 mr-2"><small className="text-muted">Publicado por</small></p>
            </div>
            <div className="row" >
                <p className="text-start m-1 mr-2"><small className="text-muted"><strong>{name}</strong></small></p>
            </div>
            <div className="row" >
                <p className="text-start m-1 mr-2"><small className="text-muted">{_creado}</small></p>
            </div>
            {
                id_user == id_user_actual ?
                <div className="row" >
                <p className="text-start m-1 mr-2" ><small className="text-muted"><strong>Acciones</strong></small></p>
                <Link to={editUrl} className="link-primary">Editar</Link>
                </div>
                :
                null
            }
        </div>

    </div>
    <br/>
    </div>
    
    );
}

export default postItem;